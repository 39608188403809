import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListboxModule } from 'primeng/listbox';
import { StyleClassModule } from 'primeng/styleclass';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { FieldsetModule } from 'primeng/fieldset';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { StepsModule } from 'primeng/steps';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputNumberModule } from 'primeng/inputnumber';
import { MegaMenuModule } from 'primeng/megamenu';
import { RippleModule } from 'primeng/ripple';
import { ToolbarModule } from 'primeng/toolbar';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TreeModule } from 'primeng/tree';

export const PRIMENG_MODULES = [
	TableModule,
	AutoCompleteModule,
	PickListModule,
	MultiSelectModule,
	ToastModule,
	MessagesModule,
	TabViewModule,
	DropdownModule,
	CalendarModule,
	PanelModule,
	ConfirmDialogModule,
	TooltipModule,
	InputSwitchModule,
	InputTextModule,
	PasswordModule,
	ButtonModule,
	FileUploadModule,
	FieldsetModule,
	ScrollPanelModule,
	StepsModule,
	CardModule,
	ProgressSpinnerModule,
	InputNumberModule,
	MegaMenuModule,
	StyleClassModule,
	RippleModule,
	ToolbarModule,
	SidebarModule,
	BadgeModule,
	PaginatorModule,
	ListboxModule,
	DynamicDialogModule,
	TreeModule,
];

@NgModule({
	declarations: [],
	imports: [CommonModule, PRIMENG_MODULES],
	exports: [PRIMENG_MODULES],
})
export class PrimeAllModule {}
